<template>
  <div
    class="auth-wrapper auth-v1 px-2 bg-cover"
    :style="{
      'background-image': `url(${require('@/assets/images/pages/login/background-image.jpeg')})`,
    }"
  >
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <div class="brand-logo">
          <b-img
            :src="appLogoImage"
            alt="logo"
            class="w-[60px]"
          />
        </div>

        <b-card-title class="mb-1 text-center">
          Ministry of Municipalities Affairs & Agriculture
        </b-card-title>
         <b-card-title class="mb-1 text-center">
            Ground Water Monitoring System
         </b-card-title>

        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your
          password
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="simpleRules"
          #default="{ invalid }"
        >
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="email@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid || waiting"
            >
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BImg,
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BImg,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      appLogoImage: $themeConfig.app.appLogoImage,
      waiting: false,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.waiting = true
          const payload = {
            email: this.userEmail,
          }
          axios
            .post('/auth/forgot-password', payload)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Check your email for further instructions',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              setTimeout(() => {
                this.$router.push({ name: 'login' })
                this.waiting = false
              }, 900)
            })
            .catch(error => {
              this.waiting = false
              let message = 'There is an error. Please try again later'
              if (error.response.status == 400) {
                message = error.response.data.message
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
